import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout, Helmet, Section, Container } from 'components'

const ConfirmationPage = () => (
  <Layout className="confirmation-page">
    <Helmet
      title='Nomade, gestion immobilière'
      description='Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire.'
    />
    <Section>
      <Container>
        <h1>Page de confirmation</h1>
        <div className="flex">

          <div className="text">
            <p>L'envoi du courriel s'est bien déroulé!</p>
            <p>Si vous le souhaitez, vous pouvez retourner à l'accueil via ce lien</p>
            <div className="link">
              <Link to="/">
                Retour à l'accueil
                    </Link>
            </div>
          </div>

          <div className="photo">
            <StaticQuery
              query={graphql`
              query {
                placeholderImage: file(relativePath: { eq: "history1.jpg" }) {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
              render={data => (
                <Img fluid={data.placeholderImage.childImageSharp.fluid} />
              )}
            />
          </div>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default ConfirmationPage
